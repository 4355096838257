import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import SmartPlayPoker from "../assets/smartplaypoker.png";
import OtherSites from "../assets/OTHERPOKERAPPS.png";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Tooltip from "@mui/material/Tooltip";
import { Mutex } from "async-mutex"
import Logo from "../assets/Logo.png";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  ColourPalette,
  getIsMobile,
  getIsTablet,
  getSectionHeight,
  getSectionMargin,
  maxWidthNormalContent,
} from "../globalConstants";
import styled from "styled-components";
import PocketPoker from "../assets/PocketPoker.png";
import PocketPoker2 from "../assets/PocketPoker2.png";
import PocketPoker3 from "../assets/PocketPoker3.png";
import PocketPoker4 from "../assets/PocketPoker4.png";
import resultsCard from "../assets/globalRankingOnEveryHand.png";
import top10pc from "../assets/delighter.png";
import solverOutput from "../assets/solveOutput.png";
import noTrivialFolds from "../assets/noTrivialFolds.png";
import gamePlayImage from "../assets/gameScreen.jpg";
import { TiTick } from "react-icons/ti";
import { sendLog } from "../App";

let timeoutID = null
const timeoutMutex = new Mutex()

const features = [
  {
    name: "Play anytime, anywhere with no clock and no stress",
    description:
      "Online or offline, with friends or against the world. Take as long as you want for every decision; \
    you can even close the app mid-hand and come back to it later.",
    image: gamePlayImage,
  },
  {
    name: "Don't worry about bad luck",
    description: `On every hand, your score is always calculated relative to others. If on one hand you lose 100 when the average person is losing 150, you win.`,
    image: top10pc,
  },
  {
    name: "Skip trivial folds",
    description: `Don't waste your time folding bad hands and waiting for the next one to start. We won't show you a hand unless you have playable cards.`,
    image: noTrivialFolds,
  },
  {
    name: "Poker Solver Analysis after every hand*",
    description: `Game theory optimal analysis for every decision you or the AI made in every hand you play. Typical expert \
    preflop ranges are used for the analysis and are also viewable for your interest.`,
    image: solverOutput,
  },
  {
    name: "Track progress with your private global ranking",
    description: `Players are ranked from 2 through to Ace. Your grade updates every day with every tournament you play and is \
    typically accurate to +/- 1 grade. By default, your ranking and grade will remain completely anonymous to other players.`,
    image: null,
  },
  {
    name: "Play downloaded tournaments offline, sync results later",
    description: `Get complete hand analysis and provisional results while disconnected and sync up with friends and \
    the global leaderboard later.`,
    image: resultsCard,
  },
];

const styles: { [key: string]: React.CSSProperties } = {

  compareTheFeaturesTable: {
    borderRadius: "10px",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "auto"
  },
  tableCellCentered: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flex: "1",
    textAlign: "center",
  },
  tooltipHover: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "max-content",
    maxHeight: "60vh",
    overflowY: "auto",
    zIndex: 999, // Ensure the tooltip appears above other elements
    opacity: 1,
  },
};

const StyledTableCell = styled(TableCell)(({ padding }) => ({
  textAlign: "center",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: padding ?? "normal",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: "rgb(234, 234, 234)",
    cursor: "pointer",
  },
}));

const tick = (
  <span style={{ verticalAlign: "middle", color: "white", fontSize: "24px" }}>
    <CheckIcon />
  </span>
);

export default function MainCheckbox() {
  const titleHeight = "15vh"
  const isMobile: boolean = getIsMobile()
  const isTablet: boolean = getIsTablet();
  const isMobileOrTablet = isMobile || isTablet;
  return (
    <div
      className="features-checkbox-section"
      style={{
        display: "flex",
        position: "relative",
        justifyContent: "center",
        alignItems: "flex-start",
        height: getSectionHeight("featuresCheckboxHeight"),
        margin: getSectionMargin("featuresCheckboxHeight"),
        minHeight: "600px",
        width: "100%",
        maxWidth: maxWidthNormalContent,
        borderColor: "#f2f2f2",
        borderWidth: "1px",
        borderStyle: "solid",
        flexDirection: "column",
      }}
    >
      <TableContainer
        className="compare-the-features-table"
        style={styles.compareTheFeaturesTable}
      >
        <Table padding="none" style={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              <StyledTableCell padding="none" align="center" width={"50%"}>
                <div style={{ width: "100%", height: "15vh", justifyContent: "center", alignItems: "center", display: "flex" }}>
                  <b style={{ fontSize: isMobile ? "32px" : "54px" }} className="primary-heading">Features</b>
                </div>
              </StyledTableCell>
              <StyledTableCell
                className="table-cell-centered"
                align="center"
                justify="center"
                style={{ backgroundColor: "#09ba4f" }}
                padding="none"
                width={"25%"}
              >
                <img className="table-image" style={{
                  display: "flex",
                  objectFit: "contain",
                  alignItems: "center",
                  height: titleHeight,
                  width: "100%",
                  padding: "1%"
                }} src={PocketPoker3} alt="" />
              </StyledTableCell>
              <StyledTableCell className="table-cell-centered" width={"25%"} padding="none">
                <img className="table-image" style={{
                  display: "flex",
                  objectFit: "contain",
                  alignItems: "center",
                  height: titleHeight,
                  width: "100%",
                  padding: "1%"
                }} src={OtherSites} alt="" />
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {features.map((feature) => (
              <StyledTableRow key={feature.name}>
                <StyledTableCell
                  className="table-cell-centered"
                  component="th"
                  scope="row"
                >
                  <div style={{ padding: isMobileOrTablet ? "5%" : "2.9%" }}>
                    <b style={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "underline",
                      textDecorationStyle: "dotted",
                      fontSize: isMobileOrTablet ? "1.7vh" : "2.4vh",
                      flex: 2
                    }}>
                      <Tooltip
                        style={{ cursor: "pointer", fontSize: "12px" }}
                        enterTouchDelay={0}
                        title={
                          <div
                            className="tooltip-hover"
                            style={styles.tooltipHover}
                          >
                            <img
                              src={feature.image}
                              style={{
                                objectFit: "contain",
                                flex: "none",
                                maxWidth: "100%",
                              }}
                              alt=""
                            />
                            <p>{feature.description}</p>
                          </div>
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            maxWidth: "50vw", //TODO FIND A NICER SOLUTION THAN THIS
                          }}
                          onMouseEnter={() => {
                            timeoutMutex.runExclusive(() => {
                              clearTimeout(timeoutID)
                              timeoutID = setTimeout(() => { sendLog("Features! Hovered over " + feature.name) }, 300)
                            })
                          }}
                          onMouseLeave={() => {
                            timeoutMutex.runExclusive(() => {
                              clearTimeout(timeoutID)
                            })
                          }}
                        >
                          {feature.name}
                          {/* <InfoOutlinedIcon
                          style={{ margin: "5px", fontSize: "100%" }}
                        /> */}
                        </div>
                      </Tooltip>
                    </b>
                  </div>
                </StyledTableCell>
                <StyledTableCell
                  align="center"
                  style={{
                    backgroundColor: ColourPalette.chips.green,
                    flex: 1,
                  }}
                >
                  {tick}
                </StyledTableCell>
                <StyledTableCell align="center" style={{ flex: 1 }}>
                  <CloseIcon
                    style={{
                      color: ColourPalette.chips.red,
                    }}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <br /> */}
      <p style={{ color: ColourPalette.darkGrey.main, fontSize: "80%" }}>
        *Full analysis available with Elite subscription
      </p>
    </div >
  );
}
