import React from "react";
import Logo from "../assets/Logo2.png";
import { SiLinkedin } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { getIsMobile } from "../globalConstants";
import { getScreenSize, getSectionHeight } from "../globalConstants";
import PocketPokerLogoHorizontalWithBackground from "../assets/PocketPokerLogoHorizontalWithBackground.png";
import { GetTheApp } from "./homeHelper";
import { ColourPalette } from "../globalConstants";
import { sendLog } from "../App";
import { Link } from "react-router-dom";

const Footer = () => {
  const styles: { [key: string]: React.CSSProperties } = {
    footerWrapper: {
      // margin: "6rem 0rem",
      // margin: "10vh",
      display: "flex",
      // marginTop: "10rem",
      flexDirection: getIsMobile() ? "column" : "row",
      height: getSectionHeight("footerHeight"),
    },
    footerLogoContainer: {
      display: "flex",
      flex: "1",
      flexDirection: "column",
      alignItems: "center",
    },
    footerSection: {
      flex: "2",
      display: "flex",
      justifyContent: "flex-end",
    },
    footerIcons: {},
  };
  return (
    <div
      className="footer"
      id="footer"
      style={{
        width: getScreenSize().width,
        display: "flex",
        flex: 1,
        alignItems: "flex-start",
        justifyContent: "flex-start",
        // height: "100%",
      }}
    >
      <div className="footer-wrapper" style={styles.footerWrapper}>
        <div
          className="footer-logo-container"
          style={styles.footerLogoContainer}
        >
          <img
            src={PocketPokerLogoHorizontalWithBackground}
            style={{
              objectFit: "contain",
              width: "100%",
              height: "100%"
            }}
            alt=""
          />
        </div>
        {/* <div className="footer-icons">
        <FaTiktok />
        <AiFillInstagram />
        <BsYoutube />
        <SiLinkedin />
      </div> */}
        <div className="footer-section" style={styles.footerSection}>
          <div className="footer-section-columns">
            <span>Contact us via email support@playpocketpoker.com</span>
            <Link to="/privicypolicy">Privacy Policy</Link>
          </div>
          <div className="footer-section-columns">
            {/* <span className="footer-section-heading">
              Available anytime, anywhere!
            </span> */}
            <span>
              <GetTheApp />
            </span>
            <div className="footer-icons">
              {/* <FaTiktok /> */}
              <AiFillInstagram onClick={() => { sendLog("Footer! Clicked on link to Instagram"); window.open("https://www.instagram.com/playpocketpoker/") }} />
              {/* <BsYoutube />
              <SiLinkedin /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
