import React from "react";
import "../App.css";
import "../fonts/myFont.ttf"
import phoneFrame from "../assets/massiveSquarePhone.png";
import gameScreenVid from "../assets/postFlopAll.mp4";
import delighterVid from "../assets/delighter.mp4";
import playPokerAnytimeAnywhere from "../assets/PlayPokerAnytimeAnywhere.png"
import GooglePlayStore from "../assets/google-play-button.png";
import {
    adjustFontSizeForScreenWidth,
    ColourPalette,
    getIsMobile,
    getIsTablet,
    getMobileDownloadButtonValues,
    getSectionHeight,
    getSectionMargin,
    phoneFrameDimensions,
} from "../globalConstants";
import { downloadButtons } from "../globalConstants";
import { Rating, Box } from "@mui/material";
import CountUp from "react-countup";
import { Slide } from "@mui/material";
import { BorderStyle } from "@mui/icons-material";
import { sendLog } from "../App";

export const homeScreenTexts = {
    primaryHeading: "Play Poker, anytime, anywhere",
    primaryBody:
        "Looking for a <b>no gambling</b>, free-to-play poker game? Pocket Poker offers <b>jam-packed fun</b> and <b>personalized learning for all levels</b> unlike any other poker game!",
    rating: 4.6,
    noOfReviews: 28,
    bulletPointsLeft: [
        "Available 24/7, online or offline",
        "Smart, human-like AI",
        "Solver analysis on every hand",
    ],
    bulletPointsRight: [
        "No commitment",
        "Play with no limit",
        "Track progress with global ranking",
    ],
    bigNumbers: {
        // playersRatings: {
        //     text: "Players' rating",
        //     number: "4.5 / 5",
        // },
        // dealsYday: {
        //     text: "Deals played yesterday",
        //     number: 1098129,
        // },
        // regPlayers: {
        //     text: "Regular players",
        //     number: 171813,
        // },
        playersRatings: {
            text: "With virtual or real currency",
            number: "No gambling",
        },
        dealsYday: {
            text: "After every hand, tailored to your level",
            number: "Personalized Feedback",
        },
        regPlayers: {
            text: "Play 5x More Hands per Session",
            number: "More Play, No Game Clock",
        },
    },
};

export const downloadAppButton = (source, link) => {
    return (
        <div
            className="download-button" style={{ height: "100%", width: "100%" }}
        >
            <div className="button-container" style={styles.buttonContainer}>
                <img
                    src={source}
                    className="download-image"
                    style={{
                        height: "100%",
                        width: source == GooglePlayStore ? "75%" : "75%",
                        objectFit: "contain",
                    }}
                    alt=""
                    onClick={(e) => {
                        sendLog("Footer! Clicked on get the app with source " + source)
                        // alert("Stay Tuned! Pocket Poker is awaiting app store approval and will be available for download soon.")
                        e.preventDefault();
                        window.open(link);
                    }}
                />
            </div>
        </div>
    );
};

export const GetTheApp = () => {
    return (
        <div className="download-buttons" style={styles.downloadButtons}>
            {downloadButtons.map((button) => {
                return downloadAppButton(button.source, button.link);
            })}
        </div>
    );
};

export const videoInPhone = (video, angle, left, bottom, zIndex, backupPhoto = null) => {
    console.log(`rotate(${angle}deg)`);

    const transformVideoStyles: React.CSSProperties = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        transform: `rotate(${angle}deg)`,
        zIndex: zIndex
    };

    return (
        <div className="video-in-phone" style={transformVideoStyles}>
            <div className="home-video-container" style={styles.homeVideoContainer}>
                {backupPhoto != null ? <div
                    style={{
                        position: "absolute",
                        bottom: bottom,
                        left: left,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            position: "relative",
                            alignItems: "flex-end",
                            justifyContent: "center",
                            height: getIsMobile() ? phoneFrameDimensions.height.mobile : phoneFrameDimensions.height.desktop,
                            width: getIsMobile() ? phoneFrameDimensions.width.mobile : phoneFrameDimensions.width.desktop
                        }}
                    >
                        <img
                            src={backupPhoto}
                            style={{
                                marginBottom: "3.81%",
                                width: "79%",
                                height: "93%",
                                objectFit: "fill",
                                zIndex: -2,
                            }}
                        />
                    </div>
                </div> : null}

                <div
                    style={{
                        position: "absolute",
                        bottom: bottom,
                        left: left,
                        width: "100%",
                        height: "100%",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            position: "relative",
                            alignItems: "flex-end",
                            justifyContent: "center",
                            height: getIsMobile() ? phoneFrameDimensions.height.mobile : phoneFrameDimensions.height.desktop,
                            width: getIsMobile() ? phoneFrameDimensions.width.mobile : phoneFrameDimensions.width.desktop
                        }}
                    >
                        <video
                            autoPlay
                            loop
                            muted
                            playsInline
                            src={video}
                            type="video/mov"
                            style={{
                                marginBottom: "3.81%",
                                width: "79%",
                                height: "93%",
                                objectFit: "fill",
                                zIndex: -2,
                            }}
                        />
                    </div>
                </div>
            </div>
            <div
                style={{
                    position: "absolute",
                    bottom: bottom,
                    left: left,
                    width: "100%",
                    height: "100%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        position: "relative",
                        alignItems: "center",
                        justifyContent: "center",
                        height: getIsMobile() ? "355.56px" : "444.44px",
                        width: getIsMobile() ? "200px" : "250px"
                    }}
                >
                    <img
                        src={phoneFrame}
                        style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                            zIndex: 2,
                        }}
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
};

export const bulletPointSection = (bulletPoints) => {
    return (
        <div className="home-bullet-points" style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: "100%",
            height: "100%",
            fontSize: getIsMobile() ? "17px" : "18px",
            margin: "0rem 2rem",
            // margin: "2%",
            whiteSpace: "nowrap",
            justifyContent: "flex-start",
            color: "#FFDD68"
        }}>
            {bulletPoints.map((point) => (
                <li>
                    <span
                        style={{
                            position: "relative",
                            left: "-10px",
                        }}
                    >
                        {point}
                    </span>
                </li>
            ))}
        </div>
    );
};

export const numbersSection = () => {
    const isMobile = getIsMobile();
    const isTablet = getIsTablet();
    console.log("fontsize")
    console.log(adjustFontSizeForScreenWidth(30))
    const fontSizeTop = isMobile ? adjustFontSizeForScreenWidth(15) : adjustFontSizeForScreenWidth(28)
    const fontSizeBottom = isMobile ? adjustFontSizeForScreenWidth(15) : adjustFontSizeForScreenWidth(22)

    return (
        <div className="home-numbers-section" style={{
            display: "flex",
            backgroundColor: "white",
            height: (isMobile || isTablet) ? "15%" : "15%", // "7.5%" : "15%",

            width: "100%",
            padding: isMobile ? 0 : "0 10%",
            flexDirection: "row",
            justifyContent: "space-around",
        }}>
            <div className="home-numbers-col" style={{
                display: "flex",
                flex: 1,
                height: "100%",
                width: "33.3%",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "space-evenly",
                padding: "3%",
                fontSize: fontSizeBottom
            }}>
                <h1 style={{ fontSize: fontSizeTop }}>{homeScreenTexts.bigNumbers.playersRatings.number}</h1>
                {homeScreenTexts.bigNumbers.playersRatings.text}
            </div>
            <div className="home-numbers-col" style={{
                display: "flex",
                flex: 1,
                height: "100%",
                width: "33.3%",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "space-evenly",
                padding: "3%",
                fontSize: fontSizeBottom
            }}>
                <h1 style={{ fontSize: fontSizeTop }}>
                    <h1 style={{ fontSize: fontSizeTop }}>{homeScreenTexts.bigNumbers.dealsYday.number}</h1>
                    {/* <CountUp end={homeScreenTexts.bigNumbers.dealsYday.number} /> */}
                </h1>
                {homeScreenTexts.bigNumbers.dealsYday.text}
            </div>
            <div className="home-numbers-col" style={{
                display: "flex",
                flex: 1,
                height: "100%",
                width: "33.3%",
                flexDirection: "column",
                textAlign: "center",
                justifyContent: "space-evenly",
                padding: "3%",
                fontSize: fontSizeBottom
            }}>
                <h1 style={{ fontSize: fontSizeTop }}>
                    <h1 style={{ fontSize: fontSizeTop }}>{homeScreenTexts.bigNumbers.regPlayers.number}</h1>
                    {/*<CountUp end={homeScreenTexts.bigNumbers.regPlayers.number} />*/}
                </h1>
                {homeScreenTexts.bigNumbers.regPlayers.text}
            </div>
        </div >
    );
};

export const styles: { [key: string]: React.CSSProperties } = {
    backgroundImage: {
        height: "85%",
        width: "100%",
        flexDirection: getIsMobile() ? "column" : "row",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    homeBannerContainer: {
        // flexDirection: getIsMobile() ? ("column" as "column") : ("row" as "row"),
        flexDirection: "column",
        position: "relative",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        flex: 1,
        // width: getIsMobile() ? "100%" : null,
        width: "100%",
    },
    homeTextSection: {
        flex: getIsMobile() ? 1 : 2,
        display: "flex",
        position: "relative",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: getIsMobile() ? null : "100%",
        width: getIsMobile() ? "100%" : null,
        color: ColourPalette.lightGrey.main
    },
    mainPageTitle: {
        textAlign: "center",
        display: "flex",
        /* font-size: clamp(2rem, 3vw, 4rem); */
        fontSize: "clamp(3.5rem, 2vw, 5rem)",
        /* color: #4c4c4c; */
        flex: 1,
        width: "80%",
        justifyContent: "center",
        alignItems: "center",
        color: ColourPalette.lightGrey.main
    },
    homeBulletPointSection: {
        display: "flex",
        flex: 1,
        justifyContent: "space-evenly",
        alignItems: "flex-start",
        width: "100%",
    },
    downloadButtons: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "row",
        flex: 1,
        width: "100%",
        height: "100%",
    },
    downloadButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        width: "100%",
        height: "100%"
    },
    homeBackgroundImage: {
        objectFit: "fill",
        width: "100%",
        height: "100%"
    },
    homeVideoContainer: {
        display: "flex",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        width: "100%",
        height: getIsMobile() ? "auto" : "100%",
        objectFit: "contain",
    },
    homeTextAndVideoContainer: {
        display: "flex",
        flex: 1,
        width: "100%",
        height: "85%",
    },
};


