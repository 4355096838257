import React from "react";
import "../App.css";
import "../fonts/myFont.ttf"
import phoneFrame from "../assets/massiveSquarePhone.png";
import gameScreenVid from "../assets/postFlopAll.mp4";
import delighterVid from "../assets/delighter.mp4";
import playPokerAnytimeAnywhere from "../assets/PlayPokerAnytimeAnywhere.png"
import GooglePlayStore from "../assets/google-play-button.png";

import {
  ColourPalette,
  getIsMobile,
  getIsTablet,
  getMobileDownloadButtonValues,
  getSectionHeight,
  getSectionMargin,
} from "../globalConstants";
import { downloadButtons } from "../globalConstants";
import { Rating, Box } from "@mui/material";
import CountUp from "react-countup";
import { Slide } from "@mui/material";
import { leadSection } from "./leadSection";
import { numbersSection } from "./homeHelper";

const Home = () => {

  return (
    <div className="home-container" style={{
      display: "flex",
      height: getSectionHeight("homeHeight"),
      alignItems: "center",
      justifyContent: "flex-start",
      width: "100%",
      position: "relative",
      flexDirection: "column",
      overflow: "hidden",
      minHeight: getIsMobile() ? "1200px" : (getIsTablet() ? "1200px" : "600px")
    }}>
      {leadSection()}
      {numbersSection()}
    </div >
  );
};

export default Home;