
import { getStorage } from 'firebase/storage';
import { initializeApp } from 'firebase/app';



// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAf4LAgDBesL_MG7EvyXbmNaucDpIOYlqI",
    authDomain: "duplicatepoker-a2b78.firebaseapp.com",
    projectId: "duplicatepoker-a2b78",
    storageBucket: "duplicatepoker-a2b78.appspot.com",
    messagingSenderId: "747084805136",
    appId: "1:747084805136:web:93a7f141fd57fb12290695",
    measurementId: "G-BV6SDZL8P0"
};
const app = initializeApp(firebaseConfig);
const storage = getStorage(app)
export { storage }
