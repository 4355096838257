import { useState, useEffect } from "react";

function useScreenSize() {
  const cachedScreenSize = sessionStorage.getItem("screenSize");
  const initialScreenSize = cachedScreenSize
    ? JSON.parse(cachedScreenSize)
    : getCurrentDimension();

  const [screenSize, setScreenSize] = useState(initialScreenSize);

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      const newScreenSize = getCurrentDimension();
      setScreenSize(newScreenSize);
      sessionStorage.setItem("screenSize", JSON.stringify(newScreenSize));
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, []);

  return screenSize;
}

export default useScreenSize;
