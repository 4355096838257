import { uploadString, ref } from "firebase/storage";
import { storage } from "./firebaseConfig";
import { Logger } from "./Logger";

export class FirebaseAPI {
    static async uploadLogToStorage(logger: Logger): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                const UID = logger.getUID();
                const logsFormatted = logger.getLogs()
                const filename = `${UID}.txt`;
                const storageRef = ref(storage, 'websiteLogs/' + filename);

                // Upload the JSON string to Firebase Storage
                uploadString(storageRef, logsFormatted)
                    .then(() => {
                        console.log("Logs: " + logsFormatted)
                        console.log('Logs uploaded successfully.');
                        resolve();
                    })
                    .catch((error) => {
                        console.error('Error uploading Logs:', error);
                        reject(error);
                    });
            } catch (error) {
                console.error('Error uploading Logs:', error);
                reject(error);
            }
        });
    };
}