import React, { useState } from "react";
import Logo from "../assets/Logo2.png";
import InfoIcon from "@mui/icons-material/Info";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import CommentRounded from "@mui/icons-material/CommentRounded";
import GetAppIcon from "@mui/icons-material/GetApp";
import MenuIcon from "@mui/icons-material/Menu";
import { HiOutlineBars3 } from "react-icons/hi2";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { getIsMobile, getIsTablet, getScreenSize } from "../globalConstants";
import PocketPoker from "../assets/PocketPoker.png";
import PocketPoker2 from "../assets/PocketPoker2.png";
import { GetTheApp, downloadAppButton } from "./homeHelper";
import { getMobileDownloadButtonValues } from "../globalConstants";
import { sendLog } from "../App";

const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);

  const mobileNavBarButton = () => {
    const vals = getMobileDownloadButtonValues();
    if (vals != null) {
      return downloadAppButton(vals.source, vals.link);
    } else {
      return null;
    }
  };

  const menuOptions = getIsTablet() || getIsMobile() ? [
    { text: "Subscriptions", icon: <SubscriptionsIcon /> },
    { text: "About", icon: <InfoIcon /> },
    // { text: "Reviews", icon: <CommentRounded /> },
  ] : [
    { text: "Get the App", icon: <GetAppIcon /> },
    { text: "Subscriptions", icon: <SubscriptionsIcon /> },
    { text: "About", icon: <InfoIcon /> },
    // { text: "Reviews", icon: <CommentRounded /> },
  ];
  const [hoverDict, setHoverDict] = useState({
    "Get the App": false,
    About: false,
    Subscriptions: false,
    Testimonials: false,
  });

  const handleClick = (menuOption) => {
    var anchor;
    switch (menuOption) {
      case "Get the App":
        sendLog("Navbar! Clicked Get the App")
        anchor = document.querySelector("#top-of-page");
        anchor.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "About":
        sendLog("Navbar! Clicked About")
        anchor = document.querySelector("#about-section");
        anchor.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "Subscriptions":
        sendLog("Navbar! Clicked Subscriptions")
        anchor = document.querySelector("#subscriptions-page-wrapper");
        anchor.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      case "Reviews":
        sendLog("Navbar! Clicked Reviews")
        anchor = document.querySelector("#review-section-wrapper");
        anchor.scrollIntoView({ behavior: "smooth", block: "start" });
        break;
      default:
        break;
    }
  };

  const primaryButtonStartHover = (key) => {
    let temp = { ...hoverDict };
    for (const key1 in temp) {
      temp[key1] = key1 === key;
    }
    setHoverDict(temp);
  };

  const primaryButtonEndHover = (key) => {
    let temp = { ...hoverDict };
    temp[key] = false;
    setHoverDict(temp);
  };

  const styles: { [key: string]: React.CSSProperties } = {
    navbar: {
      position: "fixed",
      top: 0,
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      justifyContent: "stretch",
      backgroundColor: "white",
      height: "10vh",
      width: getScreenSize().width,
      zIndex: 100,
    },
    navLogoContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    },
    logoImage: {
      objectFit: "contain",
      height: "100%",
      width: "75%",
    },
    navbarLinksContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      flex: getIsMobile() || getIsTablet() ? 1 : 3,
      marginRight: getIsMobile() ? "1rem" : "3rem",
      textDecoration: "none",
      color: "black",
      fontWeight: 600,
      // minWidth: "fit-content",
    },
    buttonIcon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginRight: "0.5rem",
    },
    navbarMenuContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: 0.1,
    },
    primaryButton: {
      padding: "0.9rem 1.75rem",
      backgroundColor: "transparent",
      outline: "none",
      border: "none",
      borderRadius: "5rem",
      fontSize: "1.1rem",
      cursor: "pointer",
      fontWeight: 600,
      transition: "0.2s",
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    getTheApp: {
      maxHeight: "100%",
      maxWidth: "100%",
    },
  };

  return (
    <div className="navbar" style={styles.navbar as React.CSSProperties}>
      <div className="nav-logo-container" style={styles.navLogoContainer}>
        <img
          className="logo-image"
          style={styles.logoImage}
          src={PocketPoker}
          alt=""
        />
      </div>
      {getIsMobile() || getIsTablet() ? (
        // Render mobile menu icon\
        <div
          className="navbar-links-container"
          style={styles.navbarLinksContainer}
        >
          {mobileNavBarButton()}
          <div
            className="navbar-menu-container"
            style={styles.navbarMenuContainer}
          >
            <MenuIcon style={{ fontSize: getIsMobile() ? 30 : 60 }} onClick={() => setOpenMenu(true)} />
          </div>
        </div>
      ) : (
        // Render links on larger screens
        <div
          className="navbar-links-container"
          style={styles.navbarLinksContainer}
        >
          {menuOptions.map((item) => {
            const itemStyle = { ...styles.primaryButton };
            if (hoverDict[item.text]) {
              itemStyle["backgroundColor"] = "rgb(234, 234, 234)";
            }
            return (
              <button
                key={item.text}
                className="primary-button"
                style={itemStyle}
                onClick={() => handleClick(item.text)}
                onMouseEnter={() => {
                  primaryButtonStartHover(item.text);
                }}
                onMouseLeave={() => {
                  primaryButtonEndHover(item.text);
                }}
              >
                <span className="button-icon" style={styles.buttonIcon}>
                  {item.icon}
                </span>
                {item.text}
              </button>
            );
          })}
          {/* <GetTheApp style={{ maxHeight: "100%" }} /> */}
        </div>
      )}
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="left">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {menuOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton onClick={() => handleClick(item.text)}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default Navbar;
