import React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import DiamondIcon from "@mui/icons-material/Diamond";
import pocketAces from "../assets/pocketPokerPocketAces.png"
import subscriptionsText from "../assets/subscriptionsText.png"
import { Mutex } from "async-mutex"
import {
  ColourPalette,
  getIsMobile,
  getIsTablet,
  getScreenSize,
  getSectionHeight,
  getSectionMargin,
  maxWidthNormalContent,
} from "../globalConstants";
import styled from "styled-components";
import GetAppIcon from "@mui/icons-material/GetApp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Tooltip from "@mui/material/Tooltip";
import { getMobileDownloadLink, adjustFontSizeForScreenWidth } from "../globalConstants";
import { display } from "@mui/system";
import { StayPrimaryLandscape } from "@mui/icons-material";
import { sendLog } from "../App";

let timeoutID = null
const timeoutMutex = new Mutex()

const Features = () => {
  const [alignment, setAlignmentMini] = React.useState("gbp"); /* initial value */
  function setAlignment(value: string) {
    setAlignmentMini(value);
    sendLog("Subscriptions! Changed currency to " + value)
  }
  const isMobile: boolean = getIsMobile();
  const isTablet: boolean = getIsTablet();
  const isMobileOrTablet: boolean = isMobile || isTablet;
  function CurrencyToggleButton(props) {

    const alignment = props.alignment;
    const setAlignment = props.setAlignment;
    const handleAlignment = (event, newAlignment) => {
      if (newAlignment !== null) {
        setAlignment(newAlignment);
      }
    };

    return (
      <div style={{ backgroundColor: "white", borderRadius: 10, borderColor: ColourPalette.primary.dark, borderWidth: "1px", borderStyle: "solid" }}>
        <ToggleButtonGroup
          color="primary"
          value={alignment}
          exclusive
          onChange={handleAlignment}
        >
          <ToggleButton value="usd">USD</ToggleButton>
          <ToggleButton value="gbp">GBP</ToggleButton>
          <ToggleButton value="eur">EUR</ToggleButton>
        </ToggleButtonGroup>
      </div>
    );
  }

  const subscriptionsInfoData = [
    {
      title: "Free Tier",
      icon: null,
      color: null,
      price: {
        usd: 0.0,
        gbp: 0.0,
        eur: 0.0,
      },
      subtext: null,
      text: [
        {
          title: "First 100 deals for free",
          image: null,
          description: "tooltip test 1",
        },
        {
          title: "Plus 10 free deals for every day you play",
          image: null,
          description: "tooltip test 2",
        },
        {
          title: "Unlimited deals vs. friends on Premium & Elite",
          image: null,
          description: "tooltip test 1",
        },
      ],
    },
    {
      title: "Premium",
      icon: <WorkspacePremiumIcon />,
      color: ColourPalette.chips.red,
      price: {
        usd: 0.00,
        gbp: 0.00,
        eur: 0.00,
      },
      subtext: "Everything in Free, and...",
      text: [
        { title: "Unlimited play", image: null, description: null },
        { title: "View & receive advice on your in-game statistics", image: null, description: null },
        { title: "Beginner-level feedback pack included", image: null, description: null },
        { title: "Add intermediate feedback pack for £3.99/mth", image: null, description: null },
        { title: "Cancel at any time", image: null, description: null },
      ],
    },
    {
      title: "Elite",
      icon: <DiamondIcon />,
      color: ColourPalette.chips.blue,
      price: {
        usd: 0.00,
        gbp: 0.00,
        eur: 0.00,
      },
      subtext: "Everything in Premium, and...",
      text: [
        { title: "Intermediate & Advanced feedback packs included", image: null, description: null },
        { title: "Poker-solver (GTO) analysis on every hand", image: null, description: null },
        { title: "Discounts on all in app courses and packs", image: null, description: null },
        { title: "Cancel at any time", image: null, description: null },
      ],
    },
  ];

  const currency_symbols = {
    usd: "$",
    gbp: "£",
    eur: "€",
  };

  const styles: { [key: string]: React.CSSProperties } = {
    subscriptionsSectionWrapper: {
      // marginTop: "10vh",
      // marginBottom: "10vh",
      // height: "85vh",
    },
    subscriptionsSectionTop: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      flexDirection: "column",
      height: "20vh",
      minHeight: "200px",
    },
    subscriptionsSectionInfo: {
      width: isMobile ? "95%" : isTablet ? "32%" : "30%",
      minHeight: isMobile ? null : "100%",
      backgroundColor: "white",
      borderColor: ColourPalette.primary.main,
      borderWidth: "2px",
      borderStyle: "solid",
      padding: "1rem 2rem",
      // borderRadius: "10px",
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      textAlign: "center",
      borderRadius: "1rem",
      color: "#505050",
      margin: !isTablet ? adjustFontSizeForScreenWidth(5) : null,

    },
    subscriptionsSectionBottom: {
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: getIsMobile() ? "center" : "stretch",
      flexDirection: getIsMobile() ? "column" : "row",
      height: "fit-content",
    },
    icon: {
      verticalAlign: "middle",
      color: null,
    },
    tooltipHover: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      height: "max-content",
      maxHeight: "60vh",
      overflowY: "auto",
      zIndex: 999, // Ensure the tooltip appears above other elements
    },
  };

  const DownloadApp = styled.button`
    padding: 0.3rem 0.7rem;
    background-color: orange;
    color: black;
    outline: none;
    border: none;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    transition: 0.2s;
    &:hover {
    }
  `;

  const downloadButtonClick = (e) => {
    if (getIsMobile()) {
      if (getMobileDownloadLink() != null) {
        e.preventDefault();
        window.open(getMobileDownloadLink());
      }
    }
  };
  const minHeightMobile = "1300px"
  const minHeightTablet = "700px"
  const minHeightDesktop = "700px"
  return (
    <div id="subscriptions-page-wrapper" className="subscriptions-page" style={{
      height: getSectionHeight("subscriptionsHeight"),
      margin: getSectionMargin("subscriptionsHeight"),
      minHeight: isMobile ? minHeightMobile : isTablet ? minHeightTablet : minHeightDesktop,
      width: "100%",
      maxWidth: maxWidthNormalContent,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}>
      <div style={{
        position: "absolute",
        height: getSectionHeight("subscriptionsHeight"),
        minHeight: isMobile ? minHeightMobile : isTablet ? minHeightTablet : minHeightDesktop,
        left: "-0.01%",
        width: getScreenSize().width,
        background: !isMobile ? 'linear-gradient(90deg, rgba(84,130,53,1) 0%, rgba(69,104,32,1) 35%, rgba(49,68,3,1) 100%)' : 'linear-gradient(90deg, rgba(84,130,53,1) 0%, rgba(69,104,32,1) 35%, rgba(49,68,3,1) 100%)',
        zIndex: -999,
        display: "flex"
      }}>
        <img style={{ position: "absolute", top: "10%", right: 0, height: isMobile ? null : "40%", width: isMobile ? "30%" : "25%", objectFit: "contain" }} src={pocketAces} alt="" />
        {isMobile ? <img style={{ position: "absolute", top: "50%", left: 0, height: isMobile ? null : "40%", width: isMobile ? "30%" : "25%", objectFit: "contain" }} src={pocketAces} alt="" /> : null}
        <img style={{ position: "absolute", bottom: "10%", left: isMobile ? null : 0, right: isMobile ? 0 : null, height: isMobile ? null : "40%", width: isMobile ? "30%" : "25%", objectFit: "contain" }} src={pocketAces} alt="" />
      </div>
      <div style={{
        position: "absolute",
        height: getSectionHeight("subscriptionsHeight"),
        minHeight: isMobile ? minHeightMobile : isTablet ? minHeightTablet : minHeightDesktop,
        left: "-0.01%",
        width: getScreenSize().width,
        background: 'linear-gradient(90deg, rgba(84,130,53,0.3) 0%, rgba(69,104,32,0.3) 35%, rgba(49,68,3,0.3) 100%)',
        zIndex: -999,
        display: "flex"
      }}>
      </div>
      <div
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <div
          className="subscriptions-section-top"
          style={styles.subscriptionsSectionTop}
        >
          {/* <h1 className="primary-heading" style={{ color: ColourPalette.yellow.light, fontSize: isMobile ? "42px" : "64px", textShadow: isMobileOrTablet ? null : "0 0 4px #314403, 0 0 7px #314403" }}>
            Subscriptions
            </h1> */}
          <img style={{ width: (isMobile ? "95%" : (isTablet ? "60%" : "45%")), objectFit: "contain" }} src={subscriptionsText}></img>
          <CurrencyToggleButton
            alignment={alignment}
            setAlignment={setAlignment}
          />
        </div>
        <div
          className="subscriptions-section-bottom"
          style={styles.subscriptionsSectionBottom}
        >
          {subscriptionsInfoData.map((data, index) => (
            <div
              className="subscriptions-section-info"
              key={data.title}
              style={styles.subscriptionsSectionInfo}
              onMouseEnter={() => {
                timeoutMutex.runExclusive(() => {
                  clearTimeout(timeoutID)
                  timeoutID = setTimeout(() => { sendLog("Subscriptions! Hovered over " + data.title) }, 300)
                })
              }}
              onMouseLeave={() => {
                timeoutMutex.runExclusive(() => {
                  clearTimeout(timeoutID)
                })
              }}
            >
              <h2>
                {data.title}
                <span style={{ verticalAlign: "middle", color: data.color }}>
                  {data.icon}
                </span>
              </h2>
              <h2>
                {data.price[alignment] == 0.0 && index > 0 ? "Free for Beta Version" : (data.price[alignment] == 0.0 ? "No credit card required" : currency_symbols[alignment] + data.price[alignment].toString() + " / month")}
              </h2>
              {data.subtext != null ? (
                <div
                  className="primary-text"
                  style={{
                    fontStyle: "italic",
                    textAlign: "left",
                    fontSize: "90%",
                  }}
                >
                  {data.subtext}
                </div>
              ) : (
                <div style={{ backgroundColor: ColourPalette.accent.main, padding: "2%", borderRadius: 10, }}><span style={{ fontWeight: "bold" }}>Available for download soon</span></div>
                // <DownloadApp onClick={downloadButtonClick}>
                //   <span style={{ verticalAlign: "middle" }}>
                //     <GetAppIcon />
                //   </span>
                //   Get the App
                // </DownloadApp>
              )}
              <br />
              {data.text.map((bulletPoint) => (
                <p>
                  {/* <Tooltip
                    style={{ cursor: "pointer" }}
                    enterTouchDelay={0}
                    title={
                      <div
                        className="tooltip-hover"
                        style={styles.tooltipHover}
                      >
                        <img
                          src={bulletPoint.image}
                          style={{
                            objectFit: "contain",
                            flex: "none",
                            // maxWidth: "100%",
                          }}
                          alt=""
                        />
                        <p>{bulletPoint.description}</p>
                      </div>
                    }
                  > */}
                  <span
                    style={{
                      flex: 1,
                      display: "flex",
                      textAlign: "left",
                      fontWeight: 600,
                      fontSize: adjustFontSizeForScreenWidth(16)
                    }}
                  >
                    <CheckCircleIcon
                      style={{
                        verticalAlign: "middle",
                        color: ColourPalette.chips.green,
                        display: "inline-block",
                      }}
                    />
                    {bulletPoint.title}
                  </span>
                  {/* </Tooltip> */}
                </p>
              ))}
            </div>
          ))}
        </div>
      </div>
      {/* <div className="more-features">
        <FeaturesCheckbox />
      </div> */}
    </div>
  );
};

export default Features;
