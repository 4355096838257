import { getScreenSize } from "../globalConstants"
import { FirebaseAPI } from "./FirebaseAPI"
import { Mutex } from 'async-mutex';

export class Logger {
  private UID: string
  private logs: string[] = [];
  private loggerMutex: Mutex;

  constructor() {
    const screenSize: { width: number, height: number } = getScreenSize()
    const time: string = new Date().toISOString()
    this.UID = time + "!" + screenSize.width.toString() + "!" + screenSize.height.toString() + "!" + (Math.random() * 1000000).toFixed(0)
    this.loggerMutex = new Mutex();
    this.addLog("initial load")
  }

  async addLog(action: string) {
    const time: string = new Date().toISOString()
    this.logs.push(time + ": " + action)
    try {
      await this.loggerMutex.runExclusive(async () => {
        await FirebaseAPI.uploadLogToStorage(this);
      })
    } catch (err) {
      console.error(err)
    }
  }

  getLogs(): string {
    let output: string = "";
    for (const log of this.logs) {
      output += log + "\n"
    }
    return output;
  }
  getUID(): string {
    return this.UID
  }
}