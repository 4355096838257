import "./App.css";
import "./fonts/myFont.ttf"
import Home from "./components/Home";
import About from "./components/About";
import Navbar from "./components/Navbar";
import FeaturesCheckbox from "./components/FeaturesCheckbox";
import Subscriptions from "./components/Subscriptions";
import Footer from "./components/Footer";
import Testimonials from "./components/Testimonials";
import useScreenSize from "./useScreenSize";
import {
  getScreenSize,
  setScreenSize,
  mainSectionHeight,
  getIsMobile,
  getMainSectionAfterHomeHeight,
  getIsTablet,
  // StylesDictionary,
} from "./globalConstants";
import React, { useEffect } from "react";
import { Logger } from "./firebaseLogging/Logger";

let logger: Logger = null;

export function sendLog(message: string) {
  if (logger != undefined || logger != null) {
    logger.addLog(message);
  } else {
    console.error("sendLog: logger is undefined or null")
  }
}

function App() {
  setScreenSize(useScreenSize());
  useEffect(() => {
    console.log("here")
    if (logger == null) {
      logger = new Logger()
    }
  }, []);
  const screenSize = getScreenSize();
  const isMobile = getIsMobile();
  const isTablet: boolean = getIsTablet();
  const isMobileOrTablet: boolean = isMobile || isTablet
  const screenWidthPC = Math.round(80 + (1 - screenSize.width / 1900) * 15)
  const styles: { [key: string]: React.CSSProperties } = {
    app: {
      display: "flex",
      justifyContent: "center",
      margin: "0rem auto",
      width: screenSize["width"]
    },
    mainSection: {
      //maxWidth: "1900px",
      justifyContent: "center",
      alignItems: "center",
      display: "inline-block",
      width: "100%",
      flexDirection: "column" as "column",
      height: mainSectionHeight(),
      marginTop: "10vh",
      flex: 1
    },
    mainAfterHome: {
      marginLeft: isMobileOrTablet ? "2.5%" : ((100 - screenWidthPC) / 2).toString() + "%",
      marginRight: isMobileOrTablet ? "2.5%" : ((100 - screenWidthPC) / 2).toString() + "%",
      width: isMobileOrTablet ? "95%" : screenWidthPC.toString() + "%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      flexDirection: "column",
      // height: getMainSectionAfterHomeHeight(),
      // minHeight: "9000px"
    },
  };
  return (
    <div className="App" id="top-of-page" style={styles.app}>
      <div className="main-section" style={styles.mainSection}>
        <Home />
        <div className="main-after-home" style={styles.mainAfterHome}>
          <FeaturesCheckbox />
          <Subscriptions />
          {/* <Testimonials /> */}
          <About />
        </div>
        <Footer />
      </div>
      <Navbar />
    </div>
  );
}

export default App;
