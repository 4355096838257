import React, { useState, useEffect } from "react";
import {
  getIsMobile,
  getScreenSize,
  getSectionHeight,
  getSectionMargin,
  ColourPalette,
  getIsTablet,
  phoneFrameDimensions,
  maxWidthNormalContent,
  adjustFontSizeForScreenWidth
} from "../globalConstants";
import "../App.css"
import styled from "styled-components";
import resultsScreen from "../assets/resultsScreen.mp4";
import postFlopAll from "../assets/postFlopAll.mp4";
import delighter from "../assets/delighter.mp4";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import BrowseGalleryIcon from "@mui/icons-material/BrowseGallery";
import gameScreenPhoto from "../assets/question_1.PNG"
import delighterPhoto from "../assets/delighterPic.png"
import resultsScreenPhoto from "../assets/answer_1.png"
import { videoInPhone } from "./homeHelper";
import { sendLog } from "../App";
import { Mutex } from "async-mutex"
import { width } from "@mui/system";
let timeoutID = null
const timeoutMutex = new Mutex()



const About = () => {
  const fontSizeTitle = getIsMobile() ? adjustFontSizeForScreenWidth(16) : adjustFontSizeForScreenWidth(28)
  const fontSizeText = getIsMobile() ? adjustFontSizeForScreenWidth(12) : adjustFontSizeForScreenWidth(18)
  const aboutTexts = {
    tabOneTitle: "Have fun",
    tabOneContent: <p style={{ fontSize: fontSizeText, height: (getIsMobile() || getIsTablet()) ? "100%" : null }} >
      {/* <strong style={{ fontSize: getIsTablet() ? "2.4vh" : "2.9vh" }} className="about-strong">Play for pride, not money</strong><br />
      We have a rapidly growing community of 50,000 active members <b>playing poker online</b> every day.<br /><br /> */}

      <br /><strong style={{ fontSize: fontSizeTitle }} >No clock, no stress</strong><br />
      Most people play better and have more fun when they don't have to make every decision in under 10 seconds as on other apps.<br /><br />

      <br /><strong style={{ fontSize: fontSizeTitle }} >Challenge friends offline</strong><br />
      No organising required as there's <b>no need to be online at the same time</b>. When you challenge a friend on PocketPoker you both play the
      same deals against our AI and compare your scores later.<br /><br />

      <br /><strong style={{ fontSize: fontSizeTitle }} >See your global ranking; join the Race2Ace!</strong><br />
      All players get a grade from 2 through to Ace which updates as you play. This is private to you and is a great way to track
      your progress. How many tournaments will you take to reach the coveted rank of Ace?<br /><br />
    </p>,
    tabTwoTitle: "Learn Poker today",
    tabTwoContent: <p style={{ fontSize: fontSizeText, height: (getIsMobile() || getIsTablet()) ? "100%" : null }} className="about-normal">
      <strong style={{ fontSize: fontSizeTitle }} >Learn how to play</strong><br />
      Poker is a fun and exciting card game that takes <b>a minute to learn but a life time to master</b>. Whether you are a
      complete beginner or a online pro, PocketPoker will improve your strategic decision-making and logical reasoning, all
      from the safety of a <b>no gambling environment</b>. <br /><br />

      <br /><strong style={{ fontSize: fontSizeTitle }} >Practice in app and surprise your friends at your poker evenings</strong><br />
      Never bluffed before? Why not try it out in a no-stakes environment where no one is watching. See instantly whether the
      computer likes your bluff or not and whether other players tried the same thing.<br /><br />

      <br /><strong style={{ fontSize: fontSizeTitle }} >Smart, human-like, artificial intelligence</strong><br />
      Our AI deviates from optimal play in typical human-like ways (too bluffy, calls too much, too conservative, etc.).
      You get <b>a new AI Personality every day</b>, providing you the perfect opportunity to practise identifying and exploiting its weaknesses.<br />
    </p>
    ,

    /*  <strong>Like us on Facebook to learn more</strong>
    Ever wondered what a static board is? Or whether pocket 5s or pocket Jacks a better bet on Q42? Give us a like on Facebook \
    to learn from our advanced concept breakdowns.<br />*/
    tabThreeTitle: "Save your time",
    tabThreeContent: <p style={{ fontSize: fontSizeText, height: (getIsMobile() || getIsTablet()) ? "100%" : null }} className="about-normal">
      <strong style={{ fontSize: fontSizeTitle }} className="about-strong">Never Fold!</strong><br />
      PocketPoker automatically filters out trivial folds so you can <b>participate in 100% of hands</b> you play;
      that means you <b>play up to 5 times more often</b> than on other apps!.There are <b>no turn timers</b> too, so you can think as long
      and carefully as you like, in your fun-packed, stress-free poker experience.<br /><br />

      <br /><strong style={{ fontSize: fontSizeTitle }} className="about-strong">Play on your commute, offline</strong><br />
      Download your daily tournaments onto your phone and play vs. the AI offline (perfect for metro journeys and flights).
      You even get a provisional global ranking whilst still unconnected. See if you beat your friends and sync with the global
      leaderboard upon regaining internet connection.<br /><br />

      <br /><strong style={{ fontSize: fontSizeTitle }} className="about-strong">Stop studying away from the table</strong><br />
      Get game-theory-optimal and exploitative analysis in the app <b>after every hand</b>. Find out where you deviated from
      the solver's recommendations and whether the line you took was also popular with other players.<br />
    </p>
  };

  const tabs = [
    {
      i: 0,
      radius: [5, 0],
      title: aboutTexts.tabOneTitle,
      icon: SentimentVerySatisfiedIcon,
      video: postFlopAll,
      content: aboutTexts.tabOneContent,
      backupPhoto: gameScreenPhoto,
    },

    {
      i: 1,
      radius: [0, 0],
      icon: AutoStoriesIcon,
      video: resultsScreen,
      title: aboutTexts.tabTwoTitle,
      content: aboutTexts.tabTwoContent,
      backupPhoto: resultsScreenPhoto,
    },
    {
      i: 2,
      radius: [0, 5],
      icon: BrowseGalleryIcon,
      video: delighter,
      title: aboutTexts.tabThreeTitle,
      content: aboutTexts.tabThreeContent,
      backupPhoto: delighterPhoto,
    },
  ];

  const screenSize = getScreenSize();
  const isMobile: boolean = getIsMobile();
  const isTablet: boolean = getIsTablet();
  const isMobileOrTablet = isMobile || isTablet
  const sectionHeight = getSectionHeight("aboutHeight")
  const [active, setActive] = useState(tabs[0].title);

  const styles: { [key: string]: React.CSSProperties } = {
    tabs: {
      paddingBottom: "1%",
      outline: "none",
      border: "none",
      cursor: "pointer",
      fontWeight: 600,
      transition: "0.2s",
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      maxWidth: "100%",
    },
    aboutSectionVideo: {
      display: "flex",
      position: "relative",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      height: "100%",
      width: "100%",
      // height: getIsMobile() ? null : "100%",
      // width: getIsMobile() ? "100%" : null,
    },
    video: {
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%",
      objectFit: "contain",
      maxHeight: isMobile ? "85vh" : "100%",
    },
    tabContentAndVideo: {
      display: "flex",
      height: "100%",
      //height: "auto",
      width: "100%",
      padding: "1rem 1rem",
      justifyContent: "flex-start",
      flexDirection: getIsMobile() ? "column" : "row",
    },
  };

  function TabGroup(props) {
    const { active, setActive } = props;

    return (
      <div
        style={{
          flex: 1,
          display: "flex",
          // height: "100%",
          height: "100%",
          width: "100%",
          maxWidth: maxWidthNormalContent,
          flexDirection: "column",
          backgroundColor: "white",
          borderBottom: "1px solid rgba(224, 224, 224, 1)",
          borderRadius: "10px",
          borderColor: ColourPalette.lightGrey.main,
          borderWidth: "1px",
          borderStyle: "solid",
        }}
      >
        <Tabs className="tabs" style={styles.tabs}>
          {tabs.map((type) => {
            const radii = {};
            if (type.radius[0] !== 0) {
              radii["borderTopLeftRadius"] = type.radius[0];
              radii["borderBottomLeftRadius"] = type.radius[0];
            }
            if (type.radius[1] !== 0) {
              radii["borderTopRightRadius"] = type.radius[1];
              radii["borderBottomRightRadius"] = type.radius[1];
            }
            return (
              <>
                <TabButton
                  key={type.title}
                  style={radii}
                  active={active === type.title}
                  onClick={() => { sendLog("About! Changed tab to " + type.title); setActive(type.title) }}
                >
                  {type.title}
                </TabButton>
              </>
            );
          })}
        </Tabs>
        <div className="tabContentAndVideo" style={styles.tabContentAndVideo}
          onMouseEnter={() => {
            timeoutMutex.runExclusive(() => {
              clearTimeout(timeoutID)
              timeoutID = setTimeout(() => { sendLog("About! Hovered over " + active + "'s text body") }, 500)
            })
          }}
          onMouseLeave={() => {
            timeoutMutex.runExclusive(() => {
              clearTimeout(timeoutID)
            })
          }}>
          {isMobileOrTablet || screenSize.height < parseInt(phoneFrameDimensions.height.desktop.split("px")[0], 10) + 275 ? null : videoSection}
          <TabContentContainer>
            <p style={{ // whiteSpace: ""; // Preserve line breaks and display them correctly
              fontSize: isTablet ? "2.3vh" : "2.7vh",
              whiteSpace: "pre-line",
            }}

            />
            {tabs.find((tab) => tab.title === active).content}
            <p />
          </TabContentContainer>
        </div>
      </div>
    );
  }
  const scaleFactor = 1.2
  const videoSection = (
    <div className="about-section-video" style={styles.aboutSectionVideo}>
      <div
        className="about-section-video-container"
        style={{
          padding: "4%",
          // position: "absolute",
          objectFit: "contain",
          // left: 0,
          height: "100%",
          width: "100%",
          display: "flex",
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          flex: 1,
          transform: 'scale(' + (scaleFactor.toString()) + "," + (scaleFactor.toString()) + ')'
        }}
      >
        <div style={{ position: "relative", width: phoneFrameDimensions.width.desktop, height: phoneFrameDimensions.height.desktop }}>
          {videoInPhone(tabs.find((tab) => tab.title === active).video, 0, 0, 0, 1, tabs.find((tab) => tab.title === active).backupPhoto)}
        </div>
        {/* <video
          // className="logo-image"
          autoPlay
          playsInline
          loop
          muted
          style={styles.video}
          src={tabs.find((tab) => tab.title === active).video}
        /> */}
      </div>
    </div>
  );

  const titleHeightPC: number = isMobile ? 16 : (isTablet ? 11 : 12)
  const tabGroupHeightPC = 100 - titleHeightPC;

  const tabGroup = (
    <div className="tab-group" style={{
      position: "relative",
      display: "flex",
      flex: 1,
      width: "100%",
      justifyContent: "space-evenly",
      alignItems: "center",
      height: tabGroupHeightPC.toString() + "%",
      minHeight: tabGroupHeightPC.toString() + "%",
      flexDirection: "column"
    }}>
      <TabGroup active={active} setActive={setActive} />
    </div>
  );

  return (
    <div
      className="about-section"
      id="about-section"
      style={{
        display: "inline-block",
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: sectionHeight,
        width: "100%",
        minHeight: isMobileOrTablet ? (isMobile ? adjustFontSizeForScreenWidth(screenSize.width < 450 ? 750 : 900) : "600px") : "700px",
        margin: getSectionMargin("aboutHeight"),
      }}
    >
      <div className="about-section-title" style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
        height: titleHeightPC.toString() + "%",
        minHeight: titleHeightPC.toString() + "%",
      }}>
        <h1 style={{
          fontSize: isMobile ? "32px" : (isTablet ? "42px" : "54px"),
          textAlign: "center"
        }}>Why play our free poker game?</h1>
      </div>
      {tabGroup}
    </div>
  );
};

const TabButton = styled.button`
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-wrap: no;
  width: 100%;
  // padding: 0.5rem 0.5rem;
  padding: 1rem 1rem;
  background-color: white;
  outline: none;
  border: none;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: black;
  transition: 0.2s;
  align-items: center;
  justify-content: center;
  // border-radius: 0px;
  opacity: 1;
  border-color: ${ColourPalette.lightGrey.main};
  border-width: 1px;
  border-style: solid;
  ${({ active }) =>
    active &&
    `
    opacity: 1;
    border-radius:5px;
    transform: scale(1.07);
    border-bottom: 3px solid ${ColourPalette.primary.light};
    opacity: 1;
    color: ${ColourPalette.primary.light} !important;
  `}
  &:hover {
    transform: scale(1.07);
    border-bottom: none;
    opacity: 1;
    border-radius: 5px;
    background-color: ${ColourPalette.primary.light};
    color: white !important;
  }
`;



const Tabs = styled.div`
  display: flex;
  width: 100%;
`;

const TabContentContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 2;
`;

export default About;
