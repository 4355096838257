import React from "react";
import "../App.css";
import "../fonts/myFont.ttf"
import phoneFrame from "../assets/massiveSquarePhone.png";
import gameScreenVid from "../assets/postFlopAll.mp4";
import delighterVid from "../assets/delighter.mp4";
import playPokerAnytimeAnywhere from "../assets/PlayPokerAnytimeAnywhere.png"
import gameScreenPhoto from "../assets/question_1.PNG"
import delighterPhoto from "../assets/delighterPic.png"
import pocketPokerLogoSquare from "../assets/logoRotatedAndDistant.png"
import GooglePlayStore from "../assets/google-play-button.png";
import {
    ColourPalette,
    getIsMobile,
    getIsTablet,
    getMobileDownloadButtonValues,
    getScreenSize,
    getSectionHeight,
    getSectionMargin,
} from "../globalConstants";
import { downloadButtons, phoneFrameDimensions, adjustFontSizeForScreenWidth } from "../globalConstants";
import { Rating, Box } from "@mui/material";
import CountUp from "react-countup";
import { Slide } from "@mui/material";
import { bulletPointSection, homeScreenTexts, styles, videoInPhone } from "./homeHelper";
import { sendLog } from "../App";


function backgroundDiv(isMobile: boolean, isTablet: boolean, isMobileOrTablet: boolean): JSX.Element {
    return (
        <div
            style={{
                position: "absolute",
                height: "100%",
                top: 0,
                left: 0,
                width: "100%",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                background: 'linear-gradient(90deg, rgba(84,130,53,1) 0%, rgba(69,104,32,1) 35%, rgba(63,94,24,1) 53%, rgba(49,68,3,1) 100%)'
            }}
        >
            <div
                style={{
                    position: "absolute",
                    height: "100%",
                    top: 0,
                    left: 0,
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    backgroundImage: "url(" + pocketPokerLogoSquare + ")",
                    backgroundSize: isMobile ? "200px" : (isTablet ? "350px" : "320px")
                }}
            >
            </div>
            <div
                style={{
                    position: "absolute",
                    height: "100%",
                    top: 0,
                    left: 0,
                    width: "100%",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    background: isMobileOrTablet ?
                        'linear-gradient(90deg, rgba(84,130,53,0.95) 0%, rgba(69,104,32,0.95) 35%, rgba(63,94,24,0.95) 53%,  rgba(49,68,3,0.9) 100%)'
                        :
                        'linear-gradient(90deg, rgba(84,130,53,0.95) 0%, rgba(69,104,32,0.95) 35%, rgba(63,94,24,0.95) 53%,  rgba(49,68,3,0.3) 100%)'
                }}
            >
            </div>

        </div>

    )
}

function textTop(isMobile: boolean, isTablet: boolean, isMobileOrTablet: boolean): JSX.Element {
    return (
        <div
            className="home-text-top"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: isMobileOrTablet ? "100%" : "60%"
            }}
        >
            {!isMobile ? <div style={{ height: "11.7%", display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}>
            </div> : null}
            <div style={{
                height: isMobileOrTablet ? "40%" : "47.6%", width: "100%", display: "flex", justifyContent: "center", alignItems: "center"
            }}>
                <img style={{ objectFit: "contain", height: "100%", width: isMobile || isTablet ? "100%" : "75%" }} src={playPokerAnytimeAnywhere} />
            </div>
            <div
                className="home-rating-section"
                style={{
                    display: "flex",
                    height: isMobileOrTablet ? (isMobile ? "5%" : "20%") : "5%",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "0rem 0.25rem",
                    fontSize: "15px", //TODO COME BACK TO THIS
                }}
            >
                {/* <Rating
                    name="text-feedback"
                    defaultValue={homeScreenTexts.rating}
                    precision={0.01}
                    size="large"
                    readOnly
                />
                <Box>
                    <p style={{ color: "#FFDD68" }}>
                        <b>{homeScreenTexts.rating}/5 </b>
                        {homeScreenTexts.noOfReviews} reviews
                    </p>
                </Box> */}
            </div>
            <div style={{ height: isMobileOrTablet ? "40%" : "29%", display: "flex", padding: "1%" }}>
                <div style={{ flex: 1, display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <p
                        className="main-page-text"
                        style={{
                            display: "inline",
                            textAlign: "justify",
                            textJustify: "inter-word",
                            flexDirection: "column",
                            paddingLeft: "5%",
                            paddingRight: "5%",
                            fontSize: isMobile ? adjustFontSizeForScreenWidth(17) : adjustFontSizeForScreenWidth(24),
                            width: "80%",
                            maxHeight: "100%",
                            color: "#FFDD68",
                            flex: 1,
                            alignItems: "center",
                            justifyItems: "center",
                        }}
                        dangerouslySetInnerHTML={{
                            __html: homeScreenTexts.primaryBody,
                        }}
                    ></p>
                </div>
            </div>
        </div>
    )
}

function textBottom(isMobile: boolean, isTablet: boolean, isMobileOrTablet: boolean) {
    return (
        <div
            className="home-text-section-bottom"
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: isMobileOrTablet ? "100%" : "40%"
            }}
        >
            <div className="download" style={{
                display: "flex",
                flex: 1,
                height: "100%",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}>
                <div style={{ display: "flex", height: "100%", width: "100%", flex: 1, paddingLeft: "5%", paddingRight: "5%" }}>
                    <div
                        className="download-buttons"
                        style={styles.downloadButtons}
                    >
                        {isMobile ? (<div
                            className="button-container"
                            style={
                                {
                                    display: "flex",
                                    justifyContent: "space-around",
                                    flexDirection: "column",
                                    flex: 1,
                                    width: "100%",
                                    height: "100%",
                                    alignItems: "center"
                                }
                            }
                        >
                            <div style={{ display: "flex", width: "100%", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                <img
                                    src={getMobileDownloadButtonValues().source}
                                    className="download-image"
                                    style={{
                                        height: "100%",
                                        width: "75%",
                                        maxWidth: isMobile ? "300px" : 0,
                                        objectFit: "contain",
                                    }}
                                    alt=""
                                    onClick={(e) => {
                                        sendLog("Home! Clicked Download Button in text Bottom")
                                        // alert("Pocket Poker is not available for download until 2nd Stay Tuned! Pocket Poker is awaiting app store approval and will be available for download soon.ber 2023")
                                        // // e.preventDefault();
                                        window.open(getMobileDownloadButtonValues().link);
                                    }}
                                />
                            </div>
                            <div style={{ display: "flex", width: "100%", flex: 1, justifyContent: "center", alignItems: "center", flexDirection: "row", marginLeft: "12.5%" }}>
                                {bulletPointSection(homeScreenTexts.bulletPointsLeft.concat(homeScreenTexts.bulletPointsRight))}
                            </div>
                        </div>)
                            : downloadButtons.map((button, i) => {
                                console.log("i:" + i)
                                const isLeft: boolean = (i % 2 == 0)
                                return (
                                    <div
                                        className="button-container"
                                        style={
                                            {
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                flex: 1,
                                                width: "50%",
                                                height: "100%",
                                                alignItems: "center",
                                            }
                                        }
                                    >
                                        <div style={{ display: "flex", height: "100%", width: "100%", flex: 1, justifyContent: "center", alignItems: "center" }}>
                                            <img
                                                src={button.source}
                                                className="download-image"
                                                style={{
                                                    height: "100%",
                                                    width: "75%",
                                                    objectFit: "contain",
                                                }}
                                                alt=""
                                                onClick={(e) => {
                                                    sendLog("Home! Clicked Download Button with id " + i.toString())
                                                    // alert("Stay Tuned! Pocket Poker is awaiting app store approval and will be available for download soon.")
                                                    e.preventDefault();
                                                    window.open(button.link);
                                                }}
                                            />
                                        </div>
                                        <div style={{ display: "flex", height: "100%", width: "100%", flex: 1, justifyContent: "center", alignItems: "center", marginLeft: "12.5%" }}>
                                            {isLeft ? bulletPointSection(homeScreenTexts.bulletPointsLeft) : bulletPointSection(homeScreenTexts.bulletPointsRight)}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            </div>
        </div >
    )
}

function textAndButtons(isMobile: boolean, isTablet: boolean, isMobileOrTablet: boolean): JSX.Element {
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end", height: (isMobile || isTablet) ? "50%" : "100%", width: !(isMobile || isTablet) ? "67%" : "100%" }}>
            <div
                style={{
                    position: "relative",
                    height: "100%",
                    flexDirection: "column",
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 0,
                    paddingLeft: isMobile ? null : "10%",
                }}
            >
                {textTop(isMobile, isTablet, isMobileOrTablet)}
                {textBottom(isMobile, isTablet, isMobileOrTablet)}
            </div>
        </div>
    )
}

export function videosInPhones(isMobile: boolean, isTablet: boolean, isMobileOrTablet: boolean): JSX.Element {
    const screenSize = getScreenSize()
    const screenWidth = screenSize.width
    const screenHeight = screenSize.height
    const scaleFactor = isMobile ? Math.min(screenHeight / 500, screenWidth / 375) : (isTablet ? Math.max(Math.min(screenHeight / 800, screenWidth / 1100), 0.8) : Math.max(Math.min(screenHeight / 800, screenWidth / 1600), 0.8))
    return (
        <div style={{ height: (isMobileOrTablet) ? (isTablet ? "50%" : "40%") : "100%", width: !(isMobileOrTablet) ? "40%" : "100%", display: "flex" }}>
            <div
                className="home-video-section"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    width: "100%"
                }}
            >
                <Slide direction="up" in={true} mountOnEnter unmountOnExit>
                    <div
                        className="overlapping-phones-section"
                        style={{
                            position: "absolute",
                            height: getIsMobile() ? phoneFrameDimensions.height.mobile : phoneFrameDimensions.height.desktop,
                            width: getIsMobile() ? phoneFrameDimensions.width.mobile : phoneFrameDimensions.width.desktop,
                            transform: 'scale(' + (scaleFactor.toString()) + "," + (scaleFactor.toString()) + ')'
                        }}
                    >
                        {videoInPhone(gameScreenVid, "-15", isMobile ? "-30%" : (isTablet ? -100 : -120), isMobile ? -10 : (isTablet ? 20 : 0), 3, gameScreenPhoto)}
                        {videoInPhone(delighterVid, "15", isMobile ? "22%" : (isTablet ? 60 : 60), isMobile ? 50 : (isTablet ? 60 : 60), 2, delighterPhoto)}
                    </div>
                </Slide>
            </div>
        </div>
    )
}

function mainDivDesktop(isMobile: boolean, isTablet: boolean, isMobileOrTablet: boolean): JSX.Element {
    return (
        <div style={{
            height: isMobileOrTablet ? "92.5%" : "85%",
            width: "100%",
            flexDirection: isMobile ? "column" : "row",
            position: "relative",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        }}>
            {backgroundDiv(isMobile, isTablet, isMobileOrTablet)}
            {textAndButtons(isMobile, isTablet, isMobileOrTablet)}
            {videosInPhones(isMobile, isTablet, isMobileOrTablet)}
        </div>
    )
}

function mainMobileOrTablet(isMobile: boolean, isTablet: boolean, isMobileOrTablet: boolean): JSX.Element {
    return (
        <div style={{
            height: "85%",// isMobileOrTablet ? "92.5%" : "85%",
            width: "100%",
            flexDirection: isMobileOrTablet ? "column" : "row",
            position: "relative",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        }}>
            {backgroundDiv(isMobile, isTablet, isMobileOrTablet)}
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end", height: (isMobile) ? "30%" : (isTablet ? "30%" : "100%"), width: !(isMobile || isTablet) ? "67%" : "100%" }}>
                <div
                    style={{
                        position: "relative",
                        height: "100%",
                        flexDirection: "column",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 0,
                        paddingLeft: isMobileOrTablet ? null : "10%"
                    }}
                >
                    {textTop(isMobile, isTablet, isMobileOrTablet)}
                </div></div>
            {videosInPhones(isMobile, isTablet, isMobileOrTablet)}
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "flex-end", height: (isMobile) ? "30%" : (isTablet ? "20%" : "100%"), width: !(isMobile || isTablet) ? "67%" : "100%" }}>
                <div
                    style={{
                        position: "relative",
                        height: "100%",
                        flexDirection: "column",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 0,
                        paddingLeft: isMobileOrTablet ? null : "10%",
                    }}
                >
                    {textBottom(isMobile, isTablet, isMobileOrTablet)}
                </div></div>
        </div>
    )
}

export function leadSection() {
    const isMobile: boolean = getIsMobile();
    const isTablet: boolean = getIsTablet();
    const isMobileOrTablet: boolean = isMobile || isTablet;
    return (
        isMobileOrTablet ?
            mainMobileOrTablet(isMobile, isTablet, isMobileOrTablet)
            :
            mainDivDesktop(isMobile, isTablet, isMobileOrTablet)
    )
}