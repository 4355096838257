import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { RoutesMe } from "./routes"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode style={{ width: "100%" }}>
    <RoutesMe />
  </React.StrictMode>
);
