
import React from 'react';
import { BrowserRouter as Router, Route, Routes as Routes1 } from 'react-router-dom'
import { PrivicyPolicy } from "./pages/privicyPolicy";
import App from "./App";
import Home from './components/Home';
import FeaturesCheckbox from './components/FeaturesCheckbox';
import About from './components/About';
import Subscriptions from './components/Subscriptions';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import { getIsMobile, getIsTablet, getScreenSize, mainSectionHeight } from './globalConstants';

export function RoutesMe() {
    const screenSize = getScreenSize();
    const isMobile = getIsMobile();
    const isTablet: boolean = getIsTablet();
    const isMobileOrTablet: boolean = isMobile || isTablet
    const screenWidthPC = Math.round(80 + (1 - screenSize.width / 1900) * 15)
    const styles: { [key: string]: React.CSSProperties } = {
        app: {
            display: "flex",
            justifyContent: "center",
            margin: "0rem auto",
            width: screenSize["width"]
        },
        mainSection: {
            //maxWidth: "1900px",
            justifyContent: "center",
            alignItems: "center",
            display: "inline-block",
            width: "100%",
            flexDirection: "column" as "column",
            height: mainSectionHeight(),
            marginTop: "10vh",
            flex: 1
        },
        mainAfterHome: {
            marginLeft: isMobileOrTablet ? "2.5%" : ((100 - screenWidthPC) / 2).toString() + "%",
            marginRight: isMobileOrTablet ? "2.5%" : ((100 - screenWidthPC) / 2).toString() + "%",
            width: isMobileOrTablet ? "95%" : screenWidthPC.toString() + "%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: "column",
            // height: getMainSectionAfterHomeHeight(),
            // minHeight: "9000px"
        },
    };
    return (
        <div>
            <Router>
                <Routes1>
                    <Route path="/" element={
                        <App />}>
                    </Route>
                    <Route path="/privicypolicy" element={
                        <PrivicyPolicy />}>
                    </Route>
                </Routes1>
            </Router>
        </div>
    );

}