import React from "react";
import GooglePlayStore from "./assets/google-play-button.png";
import AppStore from "./assets/app-store-button.png";

let screenSize: { width: number; height: number } = { width: -1, height: -1 };
let isMobile = false;
let isTablet = false;

export function adjustFontSizeForScreenWidth(size: number): string {
  if (isMobile) {
    return (size * (Math.min(350, screenSize.width) / 350)).toFixed(0) + "px"
  }
  if (isTablet) {
    return (size * (Math.min(1100, screenSize.width) / 1100)).toFixed(0) + "px"
  }
  return (size * (Math.min(1600, screenSize.width) / 1600)).toFixed(0) + "px"
}

export const maxWidthNormalContent: string = "1900px"

export const appStoreLink = "https://testflight.apple.com/join/XdIz8OWL";
export const googlePlayLink = "https://play.google.com/store/apps/details?id=com.jono133.JCPoker&pcampaignid=web_share";
export const linkTree = "https://linktr.ee/pocketpoker"

export function isIOS() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function isAndroid() {
  return /Android/i.test(navigator.userAgent);
}

export function getMobileDownloadLink() {
  if (isIOS()) {
    return appStoreLink;
  } else if (isAndroid()) {
    return googlePlayLink;
  }
  return null;
}

export function getMobileDownloadButtonValues() {
  if (isIOS()) {
    return { source: AppStore, link: appStoreLink };
  } else if (isAndroid()) {
    return { source: GooglePlayStore, link: googlePlayLink };
  } else {
    return { source: null, link: linkTree }
  }
}

export const downloadButtons = [
  { source: AppStore, link: appStoreLink },
  {
    source: GooglePlayStore,
    link: googlePlayLink,
  },
];

export const ColourPalette = {
  primary: { light: "#6B8C07", main: "#314403", dark: "undefined" },
  accent: { light: "#F4AA78", main: "#FF9E10", dark: "#E4920E" },
  lightGrey: { light: "#F8F8F8", main: "#F2F2F2", dark: "#ECECEC" },
  darkGrey: { light: "#B0B0B0", main: "#808080", dark: "#5A5A5A" },
  yellow: { light: "#FFDD68", main: "#FFD966", dark: "#D2A000" },
  chips: { red: "#EC1216", green: "#00B84B", blue: "#4467E6" },
};

export function getScreenSize(): { width: number, height: number } {
  return screenSize;
}

export function getIsMobile(): boolean {
  return isMobile;
}

export function getIsTablet(): boolean {
  return isTablet;
}

export function setScreenSize(val: { width: number; height: number }) {
  screenSize = val;
  isMobile = screenSize.width <= 768; //TODO: should this be less than or equals to
  isTablet = !isMobile && screenSize.width <= 1100; //992 on vamikas 1023 on catherines
}

export const sectionHeights = {
  navbarHeight: {
    mobile: 10,
    desktop: 10,
    margin: 0,
  },
  homeHeight: {
    mobile: 180,
    desktop: 90,
    margin: 0,
  },
  aboutHeight: {
    mobile: 80,
    desktop: 80,
    tablet: 60,
    margin: 5,
  },
  featuresCheckboxHeight: {
    mobile: 60,
    desktop: 60,
    margin: 5,
  },
  subscriptionsHeight: {
    mobile: 200,
    desktop: 75,
    margin: 0,
  },
  footerHeight: {
    mobile: 30,
    desktop: 15,
    margin: 0,
  },
  reviewsHeight: {
    mobile: 0,//75,
    desktop: 0,//75,
    margin: 0,
  },
};

export const phoneFrameDimensions: { [bigKey: string]: { [key: string]: string } } = {
  height: { mobile: "355.56px", desktop: "444.44px" },
  width: { mobile: "200px", desktop: "250px" }
}
export function mainSectionHeight() {
  let sum = 0;
  for (const section in sectionHeights) {
    if (isMobile) {
      sum +=
        sectionHeights[section].mobile + 2 * sectionHeights[section].margin;
    } else {
      if ("tablet" in sectionHeights[section] && isTablet) {
        sum +=
          sectionHeights[section].tablet + 2 * sectionHeights[section].margin;
      } else {
        sum +=
          sectionHeights[section].desktop + 2 * sectionHeights[section].margin;
      }
    }
  }
  return sum.toString().concat("vh");
}

export function getMainSectionAfterHomeHeight() {
  let sum = 0;
  for (const section in sectionHeights) {
    if (section == "homeHeight") {
      continue;
    }
    if (isMobile) {
      sum +=
        sectionHeights[section].mobile + 2 * sectionHeights[section].margin;
    } else {
      if ("tablet" in sectionHeights[section] && isTablet) {
        sum +=
          sectionHeights[section].tablet + 2 * sectionHeights[section].margin;
      } else {
        sum +=
          sectionHeights[section].desktop + 2 * sectionHeights[section].margin;
      }
    }
  }
  console.log("height is " + sum);
  return sum.toString().concat("vh");
}

export function getSectionHeight(section: string): string {
  if (isMobile) {
    const height =
      sectionHeights[section].mobile + 2 * sectionHeights[section].margin;
    return height.toString().concat("vh");
  } else {
    if ("tablet" in sectionHeights[section] && isTablet) {
      const height =
        sectionHeights[section].tablet + 2 * sectionHeights[section].margin;
      return height.toString().concat("vh");
    } else {
      const height =
        sectionHeights[section].desktop + 2 * sectionHeights[section].margin;
      return height.toString().concat("vh");
    }
  }
}

export function getSectionMargin(section: string): string {
  if (isMobile) {
    const height = sectionHeights[section].margin;
    return height.toString().concat("vh");
  } else {
    const height = sectionHeights[section].margin;
    return height.toString().concat("vh");
  }
}

// interface AppStyles extends React.CSSProperties {}
// export type StylesDictionary = { [key: string]: AppStyles };
